import React from 'react';
import { Card, Offcanvas } from 'react-bootstrap';
import getLanguage from './Language';
import { AiFillRightCircle } from "react-icons/ai";
import "./DelayParcel.css";

class DelayParcel extends React.Component {
    state = {
        show: false
    }

    renderDelay14 = () => {
        return <Card className='card-info__wrapper card-info__cursor-pointer' onClick={this.handleOpen}>
            <Card.Body id="delay-red">
                <div style={{ fontSize: "0.9rem"}}>
                    <div className='language-right__wrapper'>
                        <div>{getLanguage(this.props.lang, "delay14")}</div>
                        <div className='language-right'><AiFillRightCircle color='#d8dadf' fontSize="1.1rem" /></div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    } 

    renderDelay10 = () => {
        return <Card className='card-info__wrapper card-info__cursor-pointer' onClick={this.handleOpen}>
            <Card.Body id="delay-red">
                <div style={{ fontSize: "0.9rem"}}>
                <div className='language-right__wrapper'>
                        <div>{getLanguage(this.props.lang, "delay10")}</div>
                        <div className='language-right'><AiFillRightCircle color='#d8dadf' fontSize="1.1rem" /></div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    }

    renderDelay7 = () => {
        return <Card className='card-info__wrapper card-info__cursor-pointer' onClick={this.handleOpen}>
            <Card.Body id="delay-orange">
                <div style={{ fontSize: "0.9rem"}}>
                <div className='language-right__wrapper'>
                        <div>{getLanguage(this.props.lang, "delay7")}</div>
                        <div className='language-right'><AiFillRightCircle color='#d8dadf' fontSize="1.1rem" /></div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    }

    renderDelay4 = () => {
        return <Card className='card-info__wrapper card-info__cursor-pointer' onClick={this.handleOpen}>
            <Card.Body id="delay-orange">
                <div style={{ fontSize: "0.9rem"}}>
                <div className='language-right__wrapper'>
                        <div>{getLanguage(this.props.lang, "delay4")}</div>
                        <div className='language-right'><AiFillRightCircle color='#d8dadf' fontSize="1.1rem" /></div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    }

    renderDelayBasedOnDays = () => {
        const { days } = this.props;
        if(days >= 14) {
            return this.renderDelay14()
        } else if(days >= 10) {
            return this.renderDelay10()
        } else if(days >= 7) {
            return this.renderDelay7()
        } else if(days >= 4) {
            return this.renderDelay4()
        }
    }

    handleOpen = () => {
        this.setState({ show: true })
    }

    handleClose = () => {
        this.setState({ show: false })
    }

    getTitleText = () => {
        const { days } = this.props;
        if(days >= 14) {
            return getLanguage(this.props.lang, `delay14`)
        } else if(days >= 10) {
            return getLanguage(this.props.lang, `delay10`)
        } else if(days >= 7) {
            return getLanguage(this.props.lang, `delay7`)
        } else if(days >= 4) {
            return getLanguage(this.props.lang, `delay4`)
        }
    }

    getDescText = () => {
        const { days } = this.props;
        if(days >= 14) {
            return getLanguage(this.props.lang, `delay14-desc`)
        } else if(days >= 10) {
            return getLanguage(this.props.lang, `delay10-desc`)
        } else if(days >= 7) {
            return getLanguage(this.props.lang, `delay7-desc`)
        } else if(days >= 4) {
            return getLanguage(this.props.lang, `delay4-desc`)
        }
    }

    render() {
        return <>
            {this.renderDelayBasedOnDays()}
            <Offcanvas show={this.state.show} onHide={this.handleClose} id="offcanvas-delay" placement='end'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <h1 className='offcanvas-delay__h1'>{this.getTitleText()}</h1>
                    <p>{this.getDescText()}</p>
                    <p>{ getLanguage(this.props.lang, "delay-text-overall") }</p>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    }
}

export default DelayParcel;