import React from 'react';
import axios from 'axios';
import {Card, ProgressBar, Button} from 'react-bootstrap'
import './Cards.css';
import Identity from './Identity';
import getLanguage from './Language';
import ProgressDot from './ProgressDot';
import WaybillInfo from './WaybillInfo';
import { GrDocumentPdf } from 'react-icons/gr'
import { TbTruckDelivery } from "react-icons/tb";
import { FiPackage } from "react-icons/fi";
import { BiPackage } from "react-icons/bi";
import { MdQrCodeScanner, MdContentCopy } from "react-icons/md";
import { BsCheck } from "react-icons/bs";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import { FaRegPaperPlane } from "react-icons/fa";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip } from "@mui/material";

class Cards extends React.Component {
    state = {
        percent: 20,
        status: "info",
        dateRef: 0,
        trackingUndefined: false,
        trackingAPI: false,
        currentStatusCode: 0,
        open: false,
        deliveryStatusElementHeight: 'auto',
        text: "",
        showCopied: false,
        copiedInterval: "",
        checkerNameUndefined: false
    }

    constructor(props){
        super(props)
        this.dateRef = React.createRef()
        this.divElement = React.createRef()
    }

    divElement = "";
    status = [
        { text: "processed", color: "#E1E3E7", order: 0 },
        { text: "packed", color: "#FFBFA9", order: 1 },
        { text: "checked", color: "#FFBFA9", order: 2 },
        { text: "picked", color: "#C9EEFF", order: 3 },
        { text: "in-transit", color: "#FFF98A", order: 4 },
        { text: "out-for-delivery", color: "#FDCE59", order: 5 },
        { text: "delivered", color: "#BFF29B", order: 6 }
    ]

    async componentDidMount() {
        const data = this.props.data;

        if(data.Packer) {
            if(data.Checker || data.Checker1) {
                //Both Packer and Checker available
                this.setState({
                    percent: 100,
                    status: 'success'
                })
            } else {
                this.setState({
                    percent: 60,
                    status: 'warning',
                    dateRef: this.dateRef.current.offsetWidth
                })
            }
        } else {
            this.setState({
                percent: 20,
                status: ''
            })
        }

        // if(data.Waybill && data.Waybill.startsWith("http")) {
        //     if(data.Waybill.endsWith("undefined") || data.Waybill === "https://www.tracking.my/") {
        //         this.setState({ trackingUndefined: true, trackingAPI: false })
        //     } else {
        //         this.setState({ trackingAPI: true })
        //     }
        // } else if(data.Waybill && data.Waybill.startsWith(618)) { //ZTO
        //     this.setState({ trackingAPI: true })
        // } else if(data.Waybill && data.Waybill.startsWith(60)) { // BEST
        //     this.setState({ trackingAPI: true })
        // } else if(data.Waybill && data.Waybill.startsWith("TP")) { //TELEPORT SG
        //     this.setState({ trackingAPI: false })
        // } else {
        //     this.setState({ trackingUndefined: true, trackingAPI: false })
        // }

        if(!this.isWaybillValid(data)) this.setState({ trackingUndefined: true });
        else this.setState({ trackingUndefined: false });

        // Check whether tracking - latest_status is available
        // If not available, which means it is returning 500
        if(data.tracking && data.tracking.latest_status) {
            this.setState({ trackingAPI: true})
        } else {
            this.setState({ trackingAPI: false})
        }

        if(data.tracking && data.tracking.latest_status && data.tracking.latest_status !== "pending" ) {
            // this.setState({ currentStatusCode: 4})
            this.setState({ currentStatusCode: this.getCurrentStatusCode(data.tracking.latest_status)})
        } else {
            this.setState({ currentStatusCode: this.getCurrentStatusCodeBeforeTracking()})
        }

        if(data.Packer) {
            if(data.Checker || data.Checker1) {
                //Both Packer and Checker available
                if(data.Checker1 && !data.Checker) {
                    this.setState({ checkerNameUndefined: true })
                    const results = await axios.get(`https://sheet.best/api/sheets/166d111a-5945-4192-8e0a-d10b69cf61fb/tabs/Checker/query?email=${data.Checker1}`);
                    data.Checker = results.data[0].Checker;
                    
                }
            }
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
        if(window.innerWidth > 1200 && prevState.trackingAPI !== this.state.trackingAPI) {
            this.setState({ deliveryStatusElementHeight: this.divElement.current.scrollHeight })
        }

        if(this.divElement && this.divElement.current && this.divElement.current.scrollHeight && 
            prevState.deliveryStatusElementHeight !== this.divElement.current.scrollHeight) {
            this.setState({ deliveryStatusElementHeight: this.divElement.current.scrollHeight })
        }
    }

    openCollapedText = () => {
        this.setState({ open: !this.state.open })
    }

    getCurrentStatusCode = (latestStatus) => {
        switch (latestStatus) {
            case "delivered": return 6;
            case "out_for_delivery": return 5;
            case "delivery_office": return 4;
            case "in_transit": return 4;
            case "info_received": return 3;
            default: return 0;
        }
    }

    getCurrentStatusCodeBeforeTracking = () => {
        const { data } = this.props;
        if(data.Checker || data.Checker1) {
           return 2;
        } else if(data.Packer) {
            return 1;
        } else {
            return 0;
        }
    }

    getChecker = (c, c1) => {
        if(c) {
            // Checker Name
            return c;
        } else {
            // Check Email
            return "Default";
        }
    }

    setMarginTopStyle = () => {
        if(this.state.trackingAPI && window.innerWidth > 1200) return { marginTop: this.state.trackingAPI && window.innerWidth > 1200 && "3.5rem"};
        else return {};
    }

    chinese = (data) => {
        if(data.Packer) {
            if(data.Checker || data.Checker1) {
                //Both Packer and Checker available
                return <div className='card-status__chinese' style={this.setMarginTopStyle()}>发货啦！小助理 <span className='font-weight-600'><u>{data.Packer}</u></span> 和 <span className='font-weight-600'><u>{this.getChecker(data.Checker, data.Checker1)}</u></span> 已确保您的货完好无缺</div>
            } else {
                return <div className='card-status__chinese' style={this.setMarginTopStyle()}>小助理 <span className='font-weight-600'><u>{data.Packer}</u></span> 已经帮您把包裹包装好了，正在等待严格质量检查。</div>
            }
        } else {
            return <div className='card-status__chinese' style={this.setMarginTopStyle()}>您的包裹正准备当中！</div>
        }
    }

    chineseTraditional = (data) => {
        if(data.Packer) {
            if(data.Checker || data.Checker1) {
                //Both Packer and Checker available
                return <div className='card-status__chinese' style={this.setMarginTopStyle()}>發貨啦！小助理 <span className='font-weight-600'><u>{data.Packer}</u></span> 和 <span className='font-weight-600'><u>{this.getChecker(data.Checker, data.Checker1)}</u></span> 已確保您的貨完好無缺</div>
            } else {
                return <div className='card-status__chinese' style={this.setMarginTopStyle()}>小助理 <span className='font-weight-600'><u>{data.Packer}</u></span> 已經幫您把包裹包裝好了，正在等待嚴格質量檢查。</div>
            }
        } else {
            return <div className='card-status__chinese' style={this.setMarginTopStyle()}>您的包裹正準備當中！</div>
        }
    }

    english = (data) => {
        if(data.Packer) {
            if(data.Checker || data.Checker1) {
                //Both Packer and Checker available
                return <div className='card-status__english' style={this.setMarginTopStyle()}>Prepare to ship! Your order is packed by <span className='font-weight-600'><u>{data.Packer}</u></span> and checked by <span className='font-weight-600'><u>{this.getChecker(data.Checker, data.Checker1)}</u></span>.</div>
            } else {
                return <div className='card-status__english' style={this.setMarginTopStyle()}>Almost there! Your order is packed by <span className='font-weight-600'><u>{data.Packer}</u></span> and currently pending for quality check.</div>
            }
        } else {
            return <div className='card-status__english' style={this.setMarginTopStyle()}>Be ready! We are currently processing your order.</div>
        }
    }

    renderDate = (data) => {
        if(data.Packer) {
            if(data.Checker || data.Checker1) {
                
                return (
                    <div style={{ display: !this.state.trackingAPI ? "block" : "none" }} className='card-date-wrapper__checker'>
                        <span>
                            {data["Date_16"]}  {data["Note fr Kefu"]}
                        </span>
                    </div>
                )
            } else {
                return (
                    <div style={{ display: !this.state.trackingAPI ? "block" : "none" }} className='card-date-wrapper__non-checker'>
                        <span ref={this.dateRef} style={{ left: `calc(60% - ${this.state.dateRef}px/2)`}}>
                            {data.Date}
                        </span>
                    </div>
                )
                
            }
        } else {
            return null;
        }
        
    }

    isWaybillValid = (data) => {
        if(data.Waybill && data.Waybill.startsWith("http")) {
            if(data.Waybill.endsWith("undefined") || data.Waybill === "https://www.tracking.my/") {
                return false;
            } else {
                return true;
            }
        } else if(data.Waybill && data.Waybill.startsWith(618)) {
            return true;
        } else if(data.Waybill && data.Waybill.startsWith(60)) {
            return true;
        } else if(data.Waybill && data.Waybill.startsWith("TP")) {
            return true;
        } else {
            return false;
        }
    }


    generateWaybill = (data) => {
        if(data.Waybill && data.Waybill.startsWith("http")) {
            if(data.Waybill.endsWith("undefined") || data.Waybill === "https://www.tracking.my/") {
                
                return <div className="card-button__waybill-unknown">
                    <div>{getLanguage(this.props.lang, "upload_tracking")}</div>
                </div>;
            } else {
                return <Button 
                    className='card-button__waybill-known'
                    onClick={()=> window.open(data.Waybill, "_blank")}
                >
                    {getLanguage(this.props.lang, "track_parcel")}
                </Button>
            }
        } else if(data.Waybill && data.Waybill.startsWith(618)) {
            //ZTO tracking
            return <Button 
                className='card-button__waybill-known'
                onClick={()=> window.open(`https://www.tracking.my/zto/${data.Waybill}`, "_blank")}
            >
                {getLanguage(this.props.lang, "track_parcel")}
            </Button>
        } else if(data.Waybill && data.Waybill.startsWith(60)) {
            //ZTO tracking
            return <Button 
                className='card-button__waybill-known'
                onClick={()=> window.open(`https://www.tracking.my/best/${data.Waybill}`, "_blank")}
            >
                {getLanguage(this.props.lang, "track_parcel")}
            </Button>
        } else if(data.Waybill && data.Waybill.startsWith("34")) {
            //ARAMEX
            return <Button 
                className='card-button__waybill-known'
                onClick={()=> window.open(`https://www.aramex.com/us/en/track/track-results-new?ShipmentNumber=${data.Waybill}`, "_blank")}
            >
                {getLanguage(this.props.lang, "track_parcel")}
            </Button>
        } else if(data.Waybill && data.Waybill.startsWith("TP")) {
            //TELEPORT
            return <Button 
                className='card-button__waybill-known'
                onClick={()=> window.open(`https://teleport.delivery/track?tracking_number=${data.Waybill}`, "_blank")}
            >
                {getLanguage(this.props.lang, "track_parcel")}
            </Button>
        } else {
            
            return <div className="card-button__waybill-unknown">
                <div>{getLanguage(this.props.lang, "upload_tracking")}</div>
            </div>;
        }
    }

    generateOrderList = () => {
        return <Button className='card-button__order' onClick={() => {this.onOrderButtonClick(this.props.data['Order ID'])}}>
            {getLanguage(this.props.lang, "check_order")}
        </Button>
    }

    onOrderButtonClick = (link) => {
        this.props.history.push(`/order/${link}`);
    }

    renderLanguageResult = (lang) => {
        if(lang === "chi") {
            return this.chinese(this.props.data)
        } else if(lang === "chi-tra") {
            return this.chineseTraditional(this.props.data)
        } else if(lang === "eng") {
            return this.english(this.props.data)
        } else {
            return this.chinese(this.props.data)
        }
    }

    renderTrackingResults = () => {
        const { data } = this.props;
        // return <div className='tracking-results'>
        //     <div className='card-status__chinese'>{data.tracking.result[0].content}</div>
        //     <div className='card-status__chinese'>{data.tracking.result[1].content}</div>
        // </div>;
        return <WaybillInfo 
            openCollapedText={this.openCollapedText} 
            open={this.state.open} 
            data={data.tracking.result}
            color={this.status[this.state.currentStatusCode].color}
            pod={data.tracking.pod}
            lang={this.props.lang}
        />
    }

    getStatusText = () => {
        return getLanguage(this.props.lang, this.status[this.state.currentStatusCode].text).toUpperCase();
    }

    getStatusColor = () => {
        return this.status[this.state.currentStatusCode].color;
    }

    changeDateFormat = (d) => {
        if(!d) return;
        const month = d.split("/")[0];
        const day = d.split("/")[1];
        const year = d.split("/")[2];
        return `${day}/${month}/${year}`;
    }

    getCheckerDateTimeFormat = (date, time) => {
        console.log(date);
        if(!date || !time) {
            return "--"
        }
        const newDate = this.changeDateFormat(date);
        return `${newDate} ${time}`;
    }

    handleCopyClicked = (text) => {
        this.setState({ text });
    }

    handleOnCopy = () => {
        this.state.copiedInterval && clearInterval(this.state.copiedInterval);
        const copiedInterval = setInterval(() => {
            this.setState({ showCopied: false });
        }, 3000);
        this.setState({ showCopied: true, copiedInterval })
    }

    renderCardDeliveryStatus = () => {
        const { data } = this.props;
        return <Card.Body className='card-wrapper__delivery-status' ref={this.divElement} style={{ height: this.state.deliveryStatusElementHeight }}>
            <div className='card-wrapper__delivery-status__title' style={{ backgroundColor: this.getStatusColor() }}>{this.getStatusText()}</div>
            <div className='card-wrapper__delivery-status__body'>
                <Identity type={getLanguage(this.props.lang, "packer")} name={data.Packer} time={this.changeDateFormat(data.Date)} lang={this.props.lang}/>
                <Identity imageUrl={data.Image} type={getLanguage(this.props.lang, "checker")} lang={this.props.lang} name={data.Checker} time={this.getCheckerDateTimeFormat(data.Date_16, data["Note fr Kefu"])} showBlink={this.state.checkerNameUndefined}/>
                {data.tracking && data.tracking.info && data.tracking.info.courier && <div className='card-wrapper__delivery-status-wrapper'>
                    <div className='card-wrapper__delivery-status-box'>
                        <div className='card-express'>{getLanguage(this.props.lang, "courier")}</div>
                        <div className='card-express-value'>{data.tracking.info.courier}</div>
                    </div>
                    <div className='card-wrapper__delivery-status-box'>
                        <div className='card-express'>{getLanguage(this.props.lang, "tracking")}</div>
                        <div className='card-express-value' id='tracking-number'>{data.tracking.info.tracking}                             
                            <div className='card-express-copy2-box'>
                                { this.state.showCopied && <div className='card-express-copy2'>Copied<span className='card-express-square2'></span></div>}
                                <CopyToClipboard onCopy={this.handleOnCopy} text={!this.state.text ? data.tracking.info.tracking : this.state.text}>
                                    <MdContentCopy onClick={() => this.handleCopyClicked(data.tracking.info.tracking)} className='card-express-icon'/>
                                </CopyToClipboard>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
            <div className='card-wrapper__delivery-status__footer'>
                <Button className='card-wrapper__delivery-status__footer-button' onClick={() => {this.onOrderButtonClick(this.props.data['Order ID'])}}>
                    {getLanguage(this.props.lang, "check_order")}
                </Button>
            </div>
        </Card.Body>
    }

    renderIdentityForSmallerScreen = () => {
        const { data } = this.props;
        return <div className='card-identity-wrapper'>
            <div className='card-identity'>
                <Identity border='none' lang={this.props.lang} marginBottom='none' type={getLanguage(this.props.lang, "packer")} name={data.Packer} time={this.changeDateFormat(data.Date)}/>
                <Identity imageUrl={data.Image} lang={this.props.lang} border='none' marginBottom='none' type={getLanguage(this.props.lang, "checker")} name={data.Checker} time={this.getCheckerDateTimeFormat(data.Date_16, data["Note fr Kefu"])} showBlink={this.state.checkerNameUndefined}/>
            </div>
            {data.tracking && data.tracking.info && data.tracking.info.courier && <div className='card-express-wrapper'>
                <div className='card-express-box'>
                    <div className='card-express'>{getLanguage(this.props.lang, "courier")}</div>
                    <div className='card-express-value'>{data.tracking.info.courier}</div>
                </div>
                <div className='card-express-box'>
                    <div className='card-express'>{getLanguage(this.props.lang, "tracking")}</div>
                    <div className='card-express-value'>{data.tracking.info.tracking}                             
                        <div className='card-express-copy-box'>
                            { this.state.showCopied && <div className='card-express-copy'>Copied</div>}
                            { this.state.showCopied && <div className='card-express-square'></div>}
                            <CopyToClipboard onCopy={this.handleOnCopy} text={!this.state.text ? data.tracking.info.tracking : this.state.text}>
                                <MdContentCopy onClick={() => this.handleCopyClicked(data.tracking.info.tracking)} className='card-express-icon'/>
                            </CopyToClipboard>
                        </div>
                        
                    </div>
                </div>
            </div>}
        </div>;
    }

    renderStatusLine = (width) => {
        if(width) return <div style={{ minWidth: width, backgroundColor: this.status[this.state.currentStatusCode].color }} className="card-tracking-status-line" ></div>
        else return <div style={{ backgroundColor: this.status[this.state.currentStatusCode].color }} className="card-tracking-status-line" ></div>
    }

    renderStatusCircle = (text, right) => {
        return <div style={{ backgroundColor: this.status[this.state.currentStatusCode].color }} className="card-tracking-status-circle">
            {window.innerWidth > 576 && <div style={{ color: right ? '#CFCFCF' : "#000"}}>{text}</div>}
        </div>;
    }
    renderTrackinStatusForSmallerScreen = () => {

        return <div className='card-tracking-status-wrapper'>
            <div className='card-tracking-status'>
                {window.innerWidth > 576 && this.renderStatusLine()}
                {this.renderStatusCircle(this.status[this.state.currentStatusCode-1] ? getLanguage(this.props.lang, this.status[this.state.currentStatusCode-1].text) : "")}
                {window.innerWidth > 576 ? this.renderStatusLine() : this.renderStatusLine(45)}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='card-tracking-status__icon-wrapper' style={{ backgroundColor: this.status[this.state.currentStatusCode].color }}>{this.renderIcon()}</div>
                    <div style={{ width: this.status[this.state.currentStatusCode].text === 'out-for-delivery'? "71px" : "auto"}} className='card-tracking-status__text'>{getLanguage(this.props.lang, this.status[this.state.currentStatusCode].text).toUpperCase()}</div>
                </div>
                {window.innerWidth > 576 ? this.renderStatusLine() : this.renderStatusLine(45)}
                {this.renderStatusCircle(this.status[this.state.currentStatusCode+1] ? getLanguage(this.props.lang, this.status[this.state.currentStatusCode+1].text) : "", true)}
                {window.innerWidth > 576 && this.renderStatusLine()}
            </div>
        </div>;
    }

    renderIcon = () => {
        switch (this.state.currentStatusCode) {
            case 0: return <HiOutlineDocumentDownload fontSize="22px" color="#333" />;
            case 1: return <FiPackage fontSize="21px" color='#333' />;
            case 2: return <BiPackage fontSize="22px" color='#333' />;
            case 3: return <MdQrCodeScanner fontSize="22px" color="#333" />;
            case 4: return <FaRegPaperPlane color="#333" fontSize="16px" />;
            case 5: return <TbTruckDelivery fontSize="22px" color='#333' />;
            case 6: return <BsCheck color="#333" fontSize="28px" />;
            default: return;
        }
    }

    render() {
        console.log(this.props)
        return <Card key={this.props.index} className="card-wrapper">
            <Card.Body className='card-wrapper__delivery-info'>
                <div className='card-text__wrapper'>
                    {/* <Tooltip title={this.props.data.Remark} placement='top'> */}
                    <Tooltip title={this.props.data.Remark} placement='top' arrow>
                        <Card.Text className="card-text">{this.props.data["TO PACK"]}</Card.Text>
                    </Tooltip>
                    {this.state.trackingAPI && window.innerWidth <= 1200 && <div onClick={() => {this.onOrderButtonClick(this.props.data['Order ID'])}}><GrDocumentPdf className='card-text-icon' fontSize={"18px"} /></div>}
                </div>
                <Card.Title className="card-title" style={{ marginTop: this.state.trackingAPI && window.innerWidth < 1200 ? "-0.9rem" : "0px" }}>{this.props.data.Receiver}</Card.Title>
                {this.state.trackingAPI ? <ProgressDot lang={this.props.lang} status={this.status} currentStatusCode={this.state.currentStatusCode} /> : <ProgressBar className='card-progress' animated variant={this.state.status} label={`${this.state.percent}%`} now={this.state.percent} />}
                
                {/* {this.props.data.Packer && this.renderDate(this.props.data)} */}
                {this.renderDate(this.props.data)}
                {this.state.trackingAPI && window.innerWidth <= 768 && this.renderTrackinStatusForSmallerScreen() }
                {this.state.trackingAPI && window.innerWidth <= 1200 && this.renderIdentityForSmallerScreen()}
                <div>{this.state.currentStatusCode > 2 ? <div>{this.renderTrackingResults()}</div> : this.renderLanguageResult(this.props.lang) }</div>
                {!this.state.trackingAPI && <div className='card-button__wrapper' style={this.state.trackingUndefined ? {display: 'flex', flexDirection: 'column', alignItems: 'center'} : {}}>
                    {this.generateOrderList()}
                    <div className='card-button__waybill-wrapper' style={this.state.trackingUndefined ? {marginTop: "0.2rem", lineHeight: 1.2} : {}}>
                        {this.generateWaybill(this.props.data)}
                    </div>
                </div>}
            </Card.Body>
            {this.state.trackingAPI && window.innerWidth > 1200 && this.renderCardDeliveryStatus()}
        </Card>
    }
}

export default Cards;