import React from 'react'

class Footer extends React.Component {
    render() {
        return <div className='footer'>
            <div>#多多关心您</div>   
            <div className='margin-top--5'>#DDcares</div>
        </div>
    }
}

export default Footer;