import React from 'react';
import axios from 'axios';
import './Order.css';
import Notfound from '../notfound.svg'

import Modal from 'react-bootstrap/Modal';
import { IoMdPhotos } from "react-icons/io";
import { Spinner, Card } from 'react-bootstrap'
import getLanguage from './Language';

class Track extends React.Component {
    state = {
        results: "",
        personalInfo: {},
        itemInfo: [],
        modalShow: false,
        modalImage: "",
        display: true,
        loading: false,
        counter: 0,
        percentage: 0,
        motivation: ""
    }
        
    getOrdering = val => {
        if(!val) return 100;
        if(!val.split("#")[1]) return 100;


        switch (val.split("#")[1]) {
            case "A1": return 17;
            case "A2": return 16;
            case "A3": return 15;
            case "B1": return 14;
            case "B2": return 13;
            case "B3": return 12;
            case "C1": return 11;
            case "C2": return 10;
            case "C3": return 9;
            case "D1": return 8;
            case "D2": return 7;
            case "D3": return 6;
            case "E1": return 5;
            case "E2": return 4;
            case "E3": return 3;
            case "O1": return 2;
            case "O2": return 1;
            case "X": return 99;
            default: return 100;
        }
    }

    getColor = area => {
        switch (area.charAt(0)) {
            case "A": return "#FFADAD"
            case "B": return "#FAD9A1"
            case "C": return "#DEF5E5"
            case "D": return "#FFFBC1"
            case "E": return "#F9CEEE"
            case "O": return "#F7A4A4"
            case "X": return "#DAE5D0"
            default: return "#F5EBE0"
        }
    }

    getArea = val => {
        if(!val) return "NA";
        if(!val.split("#")[1]) return "NA";
        return val.split("#")[1];
    }

    getPersonalInfo = (d) => {
        const data = d[0].split('<tr');
        //订单偏号 data[2]
        //购买人 data[4]
        //收件人姓名 + 电话 data[8]
        //地址 data[9]
        //备注 data[10]
        this.setState({
            personalInfo: {
                orderID: data[2].split('<td')[2].split('>')[1].split("<")[0].trim(),
                fbName: data[4].split('<td')[2].split('>')[1].split("<")[0].trim(),
                orderName: data[8].split('<td')[2].split('>')[1].split("<")[0].trim(),
                hpNumber: data[8].split('<td')[4].split('>')[1].split("<")[0].trim(),
                address: data[9].split('<td')[2].split('>')[1].split("<")[0].trim(),
                remark: data[10].split('<td')[2].split('>')[1].split("<")[0].trim(),
            }
        })
    }
    
    getItemPhoto = d => {
        if(d.includes('<img')) {
            return d.split('src="')[1].split('"')[0].trim();
        } else {
            return d.split('>')[1].split("<")[0].trim();
        }
    }

    getItemInfo = (d) => {
        const itemsArray = [];

        const firstData = d[0].split('<tr').reverse()[0].split("<td");

        itemsArray.push({
            itemPhoto: this.getItemPhoto(firstData[2]),
            itemName: firstData[3].split('span')[1].split('>')[1].split("<")[0].trim(),
            itemDesc: firstData[4].split('span>')[1].split("<")[0].trim(),
            itemPrice: firstData[7].split('span>')[1].split("<")[0].trim(),
            itemQty: firstData[8].split('span>')[1].split("<")[0].trim(),
            itemOrdering: this.getOrdering(firstData[4].split('span>')[1].split("<")[0].trim()),
        })
        d.map((p, i) => {
            if(i === 0) return "";
            if(i === d.length-1) return "";
            const remainData = p.split("<td");
            itemsArray.push({
                itemPhoto: this.getItemPhoto(remainData[2]),
                itemName: remainData[3].split('span')[1].split('>')[1].split("<")[0].trim(),
                itemDesc: remainData[4].split('span>')[1].split("<")[0].trim(),
                itemPrice: remainData[7].split('span>')[1].split("<")[0].trim(),
                itemQty: remainData[8].split('span>')[1].split("<")[0].trim(),
                itemOrdering: this.getOrdering(remainData[4].split('span>')[1].split("<")[0].trim())
            })
            return "";
        })

        
        itemsArray.sort(function(a,b) {
            return a.itemOrdering - b.itemOrdering
        })
        
        this.setState({
            itemInfo: itemsArray
        })
    }

    getShortenDetails = d => {
        var arr = d.split("#");
        if(arr.length === 1 || arr.length === 2 ) {
            return d;
        } else if(arr.length === 3) {
            return `${arr[0]}${arr[2]}`
        } else {
            return d;
        }
    }

    render404 = () => {
        return <div className='notfound'>
            <img src={Notfound} alt="404 Not Found" style={{ maxWidth: "350px" }} />
            <div className='notfound-text__chi'>{getLanguage(this.props.lang, "not_process_order")}</div>
        </div>
    }

    renderSpinner = () => {
        return <Card key={this.props.index} className="card-spinner__wrapper">
            <Card.Body className="card-spinner">
                <Spinner animation="border" />
            </Card.Body>
        </Card>
    }

    handleItemClick = (i, color, productLength, event) => {
        //CIRCULAR PERCENTAGE
        // let progressBar = document.querySelector(".circular-progress");
        // let valueContainer = document.querySelector(".value-container");
        if(event.target.outerHTML.includes("path") &&
           !event.target.outerHTML.includes("item_container__right__item-name")
        ) return;

        let bgColLeftSection = document.getElementById(`item_container__left__${i}`).style.backgroundColor;
        let bgColRightQty = document.getElementById(`item_container__right__item-qty-container__${i}`).style.backgroundColor;
        
        //green
        if(bgColLeftSection === 'rgb(180, 255, 159)' && bgColRightQty === 'rgb(180, 255, 159)') { 
            document.getElementById(`item_container__left__${i}`).style.backgroundColor = color;
            document.getElementById(`item_container__right__item-qty-container__${i}`).style.backgroundColor = color;
            this.setState({ counter: this.state.counter - 1, percentage: Math.round((this.state.counter - 1)/productLength*100)})
            document.querySelector(".circular-progress").style.background = "conic-gradient(rgb(156,255,46) "+ (this.state.counter - 1)/productLength*100*3.6 +"deg,rgb(220,255,182) "+ (this.state.counter - 1)/productLength*100*3.6 +"deg)"
        } else {
            document.getElementById(`item_container__left__${i}`).style.backgroundColor = "rgb(180, 255, 159)";
            document.getElementById(`item_container__right__item-qty-container__${i}`).style.backgroundColor = "rgb(180, 255, 159)";
            this.setState({ counter: this.state.counter + 1, percentage: Math.round((this.state.counter + 1)/productLength*100)})
            document.querySelector(".circular-progress").style.background = "conic-gradient(rgb(156,255,46) "+ (this.state.counter + 1)/productLength*100*3.6 +"deg,rgb(220,255,182) "+ (this.state.counter + 1)/productLength*100*3.6 +"deg)"
        }
    }

    getMotivationWord = () => {
        const words = ["NICE JOB!", "GOOD JOB!", "EXCELLENT!", "WELL DONE!", "OUTSTANDING!", "YOU'RE FAST!", "CONGRATULATIONS!", "YOU DID WELL!", "PERFECT!", "NO MISTAKE!", "GREAT WORK!", "COOL!", "WONDERFUL!", "INCREDIBLE!", "AWESOME!", "DELIGHTFUL!", "FANTASTIC!", "WOW!", "YOU DID THIS!", "UNBELIEVABLE!"];
        var word = words[Math.floor(Math.random()*words.length)];
        return word;
    }

    async componentDidMount() {
        this.setState({ loading: true })
        const results = await axios.get(`https://yxch9n4n6e.execute-api.ap-southeast-1.amazonaws.com/latest/orders/${this.props.match.params.id}`)
        this.setState({ results, loading: false });

        //CHECK LANGUAGE
        if(!this.props.lang) {
            this.props.showLanguageModal();
        }

        let splittedData = [];
        // NO RESULTS FROM API
        if(results.data.status === 'success' && !results.data.data) {
            splittedData = ['no data']
        } else {
            try {
                splittedData = results.data.split('<!-- 表格开始 -->')[1].split('<!-- begin 快捷侧边栏 -->')[0].split('>退货<');
            } catch (error) {
                //If wrong format sent by the API
                splittedData = ['no data']
            }
        }
        
        if(splittedData[0].replace(/\s/g,'').includes('已付款,已出货')) {
            this.setState({ display: true, motivation: this.getMotivationWord() })
            this.getPersonalInfo(splittedData);
            this.getItemInfo(splittedData);
        } else {
            this.setState({ display: false })
        }
    }

    render() {
        return <div className="flex-container">
            <Modal 
                show={this.state.modalShow}
                onHide={() => {this.setState({ modalShow: false, modalImage: "" })}}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <img style={{ width: "100%" }} src={this.state.modalImage} />
                </Modal.Body>
            </Modal>
            <div></div>
            <div className='flex-container__wrapper'>
                <div className="circular-progress">
                    <div className="value-container">{this.state.percentage}%</div>
                </div>
                    
                    {this.state.loading ? this.renderSpinner() : this.state.display ? <div className='flex-container__second'>
                        <div className='flex-container__second__personal'>
                            <div className='personal-container'>
                                <div className='personal-container__orderId'>{this.state.personalInfo.orderID}</div>
                                {this.state.personalInfo.fbName === this.state.personalInfo.orderName ? <div className='personal-container__name'>{this.state.personalInfo.orderName}</div> : <div className='personal-container__name'>{this.state.personalInfo.fbName} / {this.state.personalInfo.orderName}</div>}
                                <div className='personal-container__add-hp-row'>
                                    <div className='personal-container__address'><span className='item-title'>{getLanguage(this.props.lang, "address")}</span>{this.state.personalInfo.address}</div>
                                    <div className='personal-container__hpNumber'><span className='item-title'>{getLanguage(this.props.lang, "contact_no")}</span>{this.state.personalInfo.hpNumber}</div>
                                </div>
                                {this.state.personalInfo.remark === '--' ? "" : <div><span className='item-title'>{getLanguage(this.props.lang, "remark")}</span><div className='personal-container__remark'>{this.state.personalInfo.remark}</div></div>}
                            </div>
                        </div>
                        <div className='flex-container__second__items-list'>
                            {this.state.itemInfo.map((p, i) => {
                                return <div key={i} className='item-container' onClick={(e) => {this.handleItemClick(i, this.getColor(this.getArea(p.itemDesc)), this.state.itemInfo.length, e)}}>
                                    <div className='item_container__left' id={`item_container__left__${i}`} style={{ backgroundColor: this.getColor(this.getArea(p.itemDesc))}}><b>{this.getArea(p.itemDesc)}</b></div>
                                    <div className='item_container__right'>
                                        <div className='item_container__right__item-name-container'>
                                            <div className='item-title'>{getLanguage(this.props.lang, "item_name")}</div>
                                            <div className='item_container__right__item-name'>{p.itemName}  {p.itemPhoto? <span style={{ cursor: 'pointer' }}><IoMdPhotos onClick={() => {this.setState({ modalShow: true, modalImage: p.itemPhoto })}}/></span>: ""}</div>
                                        </div>
                                        <div className='item_container__right__item-details-container'>
                                            <div>
                                                <div className='item-title'>{getLanguage(this.props.lang, "item_price")}</div>
                                                <div className='item_container__right__item-price'>{p.itemPrice}</div>
                                            </div>
                                            {this.getShortenDetails(p.itemDesc) ? <div>
                                                <div className='item-title'>{getLanguage(this.props.lang, "item_desc")}</div>
                                                <div className='item_container__right__item-desc'>{this.getShortenDetails(p.itemDesc)}</div>
                                            </div> : ""}
                                        </div>
                                        <div style={{ backgroundColor: this.getColor(this.getArea(p.itemDesc))}} className='item_container__right__item-qty-container' id={`item_container__right__item-qty-container__${i}`}>
                                            <div>{p.itemQty}</div>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div> : this.render404()}
                    <div className='order-footer'>{this.state.motivation}</div>
            </div>
            <div></div>
        </div>;        
    }
}

export default Track;