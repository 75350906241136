import React from 'react';
import './ProgressDot.css';
import { TbTruckDelivery } from "react-icons/tb";
import { FiPackage } from "react-icons/fi";
import { BiPackage } from "react-icons/bi";
import { MdQrCodeScanner } from "react-icons/md";
import { BsCheck } from "react-icons/bs";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import { FaRegPaperPlane } from "react-icons/fa";
import getLanguage from './Language';

class ProgressDot extends React.Component {
    getColor = (s) => {
        if(s.order <= this.props.currentStatusCode) return this.props.status[this.props.currentStatusCode].color;
        else return '#E8E8E8'
    }

    getLineColor = (i) => {
        if(Math.floor(i/2) <= this.props.currentStatusCode-1) return this.props.status[this.props.currentStatusCode].color;
        else return '#E8E8E8'
    }
    
    getLastItemDisplay = (i) => {
        if(i === 13) return "none" //last item
        else return "block"
    }

    getDimension = (s) => {
        if(s.order === this.props.currentStatusCode) {
            return "40px"
        } else return "12px"
    }

    getLeftPixel = (s) => {
        if(this.getDimension(s) === '40px') {
            return '-40x'
        } else {
            return '-54px'
        }
    }

    getLabelColor = s => {
        if(s.order <= this.props.currentStatusCode) {
            return 'black'
        } else {
            return '#CFCFCF'
        }
    }

    getLabelFontWeight = s => {
        if(s.order === this.props.currentStatusCode) {
            return '600'
        } else {
            return '400'
        }
    }

    getLabelFontSize = s => {
        if(s.order === this.props.currentStatusCode) {
            return '0.82rem'
        } else {
            return '0.76rem'
        }
    }

    renderIcon = (i) => {
        if(this.props.currentStatusCode !== Math.floor(i/2)) {
            return "";
        }

        switch (Math.floor(i/2)) {
            case 0: return <HiOutlineDocumentDownload color="#333" />;
            case 1: return <FiPackage color='#333' />;
            case 2: return <BiPackage color='#333' />;
            case 3: return <MdQrCodeScanner color="#333" />;
            case 4: return <FaRegPaperPlane color="#333" fontSize="16px" />;
            case 5: return <TbTruckDelivery color='#333' />;
            case 6: return <BsCheck color="#333" fontSize="24px" />;
            default: return;
        }
    }

    render() {
        const displayStatus = [];
        this.props.status.map((s, i) => {
            displayStatus.push(s);
            displayStatus.push({ text: "line-element"})
        })

        return <div className='progress-dots__wrapper'>
            <div className='progress-dots'>
                {displayStatus.map((s,i) => {
                    if(s.text === 'line-element') {
                        return <div key={i} className='progress-dots__item-line' style={{ display: this.getLastItemDisplay(i), backgroundColor: this.getLineColor(i)}}></div>
                    } else {
                        return <div key={i} className='progress-dots__item' style={{ backgroundColor: this.getColor(s), width: this.getDimension(s), height: this.getDimension(s)}}>
                            {this.renderIcon(i)}
                            <span style={{ left: this.getLeftPixel(s), color: this.getLabelColor(s), fontWeight: this.getLabelFontWeight(s), fontSize: this.getLabelFontSize(s)}}>{getLanguage(this.props.lang, s.text)}</span>
                        </div>
                    }
                })}
            </div>
        </div>
    }
}

export default ProgressDot;