import React from 'react';
import { Card, Form, Button } from 'react-bootstrap'
import Footer from './Footer';
import './Main.css';
import getLanguage  from './Language';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: "订单编号 Order ID",
            showError: false
        }
    }

    handleChange = (data) => {
        this.setState({ inputValue: data.target.value, showError: false});
    }

    handleSubmit = (e) => {
        if(e.key === "Enter") {
            if(this.validate(this.state.inputValue)) {
                this.props.history.push(`/track/${this.state.inputValue}`)
                this.setState({ showError: false })
            } else {
                this.setState({ showError: true })
            }
        }
    }

    handleClick = () => {
        if(this.validate(this.state.inputValue)) {
            this.props.history.push(`/track/${this.state.inputValue}`)
            this.setState({ showError: false })
        } else {
            this.setState({ showError: true })
        }
        
    }

    validate = (text) => {
        return text.startsWith('F04684') || text.startsWith('F4684') || text.startsWith('F09558') || text.startsWith('F9558') || text.startsWith('R9558') || text.startsWith('R09558');
    }

    componentDidMount() {
        //CHECK LANGUAGE
        if(!this.props.lang) {
            this.props.showLanguageModal();
        }
    }

    renderNoData = () => {
        return <Card className='card-search__wrapper'>
            <Card.Body className='card-search'>
                <div style={{textAlign:'center'}}>
                    <Card.Title className='card-search__title'>多多藥业 DD Herbs</Card.Title>
                    <Form.Control 
                        className='card-search__form' 
                        onKeyPress={(e) => this.handleSubmit(e)} 
                        onChange={this.handleChange}  
                        type="email" 
                        placeholder={this.state.inputValue}
                        autoFocus
                    />
                    {this.state.showError? <div className='card-search__error'>
                        <div>{getLanguage(this.props.lang, 'wrong_input')}</div>
                    </div>: <span></span>}
                    <Button 
                        className="card-search__button"
                        onClick={this.handleClick}
                    >
                        {getLanguage(this.props.lang, 'track')}
                    </Button>
                </div>
            </Card.Body>
        </Card>
    }

    render() {
        return (
            <div style={{ paddingBottom: '2rem' }}>
                {/* TIGER */}
                {/* <img style={{ width: "100%", boxShadow: "1px 2px 8px #888f9c" }} src={'https://ddlive-qrcode.s3.ap-southeast-1.amazonaws.com/cover.jpg'} /> */}
                {/* RABBIT */}
                {
                    this.renderNoData()
                }
                <Footer />
            </div>
            )
    }
}

export default Main;