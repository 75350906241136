import React from 'react';
import './Identity.css';
import lang from '../lang.json';
import getLanguage from './Language';
import { TiImage } from 'react-icons/ti'
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';

class Identity extends React.Component {
    state = {
        checkerImageModalShow: false
    }

    alternateCheckerImageModal = () => {
        this.setState({ checkerImageModalShow: !this.state.checkerImageModalShow });
    }

    generateImage = () => {
        if(!this.props.name) return <img className={`identity-wrapper__img ${this.props.showBlink && "blink_me"}`} src="https://ddlive-qrcode.s3.ap-southeast-1.amazonaws.com/user.png" />;
        if(this.props.type === lang['packer'].chi || this.props.type === lang['packer']['chi-tra'] || this.props.type === lang['packer'].eng) return <img className={`identity-wrapper__img`} src="https://ddlive-qrcode.s3.ap-southeast-1.amazonaws.com/boy.png" />;
        if(this.props.type === lang['checker'].chi || this.props.type === lang['checker']['chi-tra'] || this.props.type === lang['checker'].eng) return <img className={`identity-wrapper__img`} src="https://ddlive-qrcode.s3.ap-southeast-1.amazonaws.com/girl.png" />;
    }

    checkImageUrl = () => {
        return this.props.imageUrl !== "N/A" && this.props.imageUrl;
    }

    renderImageLogo = () => {
        if(this.props.type === getLanguage(this.props.lang, "checker") && this.checkImageUrl()) {
            return <TiImage className='identity-wrapper__checker-image-logo blink_me' onClick={this.alternateCheckerImageModal} />;
        } else return "";
    }

    renderIdentityInfo = () => {
        if(!this.props.name) {
            return <div className={`identity-wrapper__info-wrapper ${this.props.showBlink && "blink_me"}`}>
                <div className='identity-wrapper__info-type'>{this.props.type}</div>
                <div className='identity-wrapper__no-user width-no-user-110'></div>
                <div className='identity-wrapper__no-user width-no-user-70'></div>
            </div>;
        } else {
            
            return <div className='identity-wrapper__info-wrapper'>
                <div className='identity-wrapper__info-type'>{this.props.type}{this.renderImageLogo()}</div>
                <div className='identity-wrapper__info-name'>{this.props.name}</div>
                <div className='identity-wrapper__info-time'>{this.props.time === "undefined " ? "--" : this.props.time}</div>
            </div>;
        }
    }

    getImageSrc = () => {
        const s3bucket = 'https://parcel-checked-image.s3.ap-southeast-1.amazonaws.com/DocId_1AZ53tx7T19KSNx3YSWqXhAZMDySJWyqUg0Q86FUThTk/';
        const imageUrl = this.props.imageUrl && this.props.imageUrl.split('-').join('_')
        return `${s3bucket}${imageUrl}`;
    }

    renderCheckerImageModal = () => {
        return <Modal centered size='lg' show={this.state.checkerImageModalShow} onHide={this.alternateCheckerImageModal}>
            <Modal.Body>
                <Carousel indicators={false} controls={false}>
                    <Carousel.Item>
                        <img alt="checker-img" src={this.getImageSrc()} />
                    </Carousel.Item>
                </Carousel>
            </Modal.Body>
        </Modal>
    }

    render() {
        return <div className='identity-wrapper' style={{ border: this.props.border === 'none' ? "none" : "auto", marginBottom: this.props.marginBottom === 'none' ? 0 : '5px' }}>
            <div className='identity-wrapper__img-wrapper'>
                { this.generateImage() }
            </div>
            {this.renderIdentityInfo()}
            {this.renderCheckerImageModal()}
        </div>
    }
}

export default Identity;