import './App.css';
import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import Track from './components/Track'
import Order from './components/Order'
import Main from './components/Main'
import { Carousel } from 'react-responsive-carousel';
import Offcanvas from 'react-bootstrap/Offcanvas';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "./index.css";
import axios from 'axios';
import { CircleFlag } from 'react-circle-flags'
import { AiFillPlusCircle } from "react-icons/ai";

class App extends React.Component {
  getLang = (lang) => {
    if(lang === "chi" || lang === "chi-tra" || lang === "eng") {
      return lang;
    } else {
      return "";
    }
  }

  getLangModalStatus = (lang) => {
    if(lang === "chi" || lang === "chi-tra" || lang === "eng") {
      return false;
    } else {
      return true;
    }
  }

  state = { 
    index: 0, 
    imageArr: [{
      path: 'https://img.full2house.com/Upload/Form/Files/ec2_9558/202302/202302170955387181.png',
      targetUrl: "https://ec2.full2house.com/User/index.php?a=Website&id=9558",
      remark: "DD Online Shop"
    },{
      path: 'https://ddlive-qrcode.s3.ap-southeast-1.amazonaws.com/%E4%BA%8B%E4%BA%8B%E9%83%BD%E5%A6%82%E6%84%8F%EF%BC%8C%E5%85%94%E5%85%94%E7%9A%86%E5%90%90%E6%B0%A3+(4).jpg',
      targetUrl: "https://www.facebook.com/ddgrouplivechannel/",
      remark: "DD Default Banner"
    }],
    lang: this.getLang(localStorage.getItem('lang')),
    langModal: this.getLangModalStatus(localStorage.getItem('lang')),
    modalCloseButton: false
  }

  BritishFlag = () => <CircleFlag countryCode="gb" height="25" style={{ marginRight: "2px" }} />
  MalaysiaFlag = () => <CircleFlag countryCode="my" height="25" style={{ marginRight: "2px" }}/>
  ChinaFlag = () => <CircleFlag countryCode="cn" height="25" style={{ marginRight: "2px" }} />
  TaiwanFlag = () => <CircleFlag countryCode="tw" height="25" style={{ marginRight: "2px" }} />

  changeLanguage = (lang) => {
    //SET IN LOCALSTORAGE
    this.setState({ lang });
  }

  showLanguageModal = () => {
    this.setState({ langModal: true, modalCloseButton: false });
  }

  showLanguageModalWithCloseButton = () => {
    this.setState({ langModal: true, modalCloseButton: true });
  }

  hideLanguageModel = () => {
    this.setState({ langModal: false, modalCloseButton: false })
  }

  getDotStatus = (i) => {
    if(i === this.state.index) {
      return "dot-selected"
    } else {
      return "dot"
    }
  }

  getFlag = (lang) => {
    if(lang === "chi") {
      return this.ChinaFlag();
    } else if(lang === "chi-tra") {
      return this.TaiwanFlag();
    } else if(lang === "eng") {
      return this.BritishFlag();
    } else {
      return this.MalaysiaFlag();
    }
  }

  // add modal design
  // add local storage (fallback)
  // add option of chinese or english
  renderLanguageModal = (modalCloseButton) => {
    return <Offcanvas 
      show={this.state.langModal} 
      onHide={this.hideLanguageModel} 
      placement={window.screen.width <= 576 || window.innerWidth <= 576 ? "bottom" : "top"}
      backdrop={modalCloseButton || "static"}
    >
      <Offcanvas.Header 
        closeButton={modalCloseButton}
      >
        <Offcanvas.Title>选择语言 Choose your language</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className='lang' style={{ borderColor: this.state.lang === 'chi' ? "blue" : "#d8dadf"}} onClick={() => {
          localStorage.setItem('lang', 'chi');
          this.setState({ lang: 'chi', langModal: false })
        }}>{this.ChinaFlag()} 华语（简体）</div>
        <div className='lang' style={{ borderColor: this.state.lang === 'chi-tra' ? "blue" : "#d8dadf"}} onClick={() => {
          localStorage.setItem('lang', 'chi-tra');
          this.setState({ lang: 'chi-tra', langModal: false })
        }}>{this.TaiwanFlag()} 華語（繁體）</div>
        <div className='lang' style={{ borderColor: this.state.lang === 'eng' ? "blue" : "#d8dadf"}} onClick={() => {
          localStorage.setItem('lang', 'eng');
          this.setState({ lang: 'eng', langModal: false })
        }}>{this.BritishFlag()} English</div>
      
      </Offcanvas.Body>
    </Offcanvas>
  }

  renderLanguageButton = () => {
    return <div className='language-button' onClick={this.showLanguageModalWithCloseButton}>
      <div>{this.getFlag(this.state.lang)} 语言 Language</div>
      <div className='language-plus'><AiFillPlusCircle color='#d8dadf' fontSize="1.1rem" /></div>
    </div>
  }
  
  async componentDidMount() {
    try {
      const imageArr = await axios.get('https://sheet.best/api/sheets/f347f2c4-84f8-42e7-975e-fe76d30ea786');
      this.setState({ imageArr: [...imageArr.data, ...this.state.imageArr]})
    } catch (error) {
      console.log(error);
    }
  }
  
  render() {
    return(
      <div style={{ position: "relative", backgroundColor: "#f0f2f5", height: "100%"}} >
          {this.renderLanguageModal(this.state.modalCloseButton)}
          <div className='app-wrapper__header-wrapper'>
              <Carousel 
                  className='app-wrapper__header-bg' 
                  stopOnHover={false} 
                  autoPlay 
                  interval={4000} 
                  infiniteLoop={true}
                  selectedItem={this.state.index}
                  swipeable={false}
                  // renderIndicator={(onClickHandler, isSelected, index, label) => {
                  //     return <button onClick={() => {
                  //         this.setState({ index })
                  //         onClickHandler();
                  //     }}>{index}</button>
                  // }}
              >
                  {this.state.imageArr.map((imgObj, i) => {
                      return <div key={i} onClick={() => {window.open(imgObj.targetUrl, "_blank")}}>
                          <img src={imgObj.path} />
                      </div>
                  })}
              </Carousel>
              <div className='app-wrapper__header'>
                <div></div>
                <Carousel 
                    className='app-wrapper__header__image' 
                    stopOnHover={false} 
                    autoPlay 
                    interval={4000} 
                    infiniteLoop={true}
                    selectedItem={this.state.index}
                    swipeable={false}
                    onChange = {(index) => {
                      this.setState({ index })
                    }}
                    // This is the dots
                    renderIndicator={(onClickHandler, onClickHandlerisSelected, index, label) => {
                        return <span className={`carousel-indicator ${this.getDotStatus(index)}`} onClick={() => {
                            this.setState({ index })
                        }}></span>
                    }}
                >
                    {this.state.imageArr.map((imgObj, i) => {
                        return <div key={i} onClick={() => {window.open(imgObj.targetUrl, "_blank")}}>
                            <img src={imgObj.path} />
                        </div>
                    })}
                </Carousel>
                <div></div>
              </div>
          </div>

        <div className='app-wrapper__data-wrapper'>
          <div></div>
          <div className='app-wrapper__data'>
            {this.renderLanguageButton()}
            <BrowserRouter>
              <Switch>
                <Route path="/" exact render={(props) => <Main {...props} lang={this.state.lang} showLanguageModal={this.showLanguageModal} />} />
                <Route path="/track/:id" exact render={(props) => <Track {...props} route="track" lang={this.state.lang} showLanguageModal={this.showLanguageModal} />} />
                <Route path="/waybill/:id" exact render={(props) => <Track {...props} route="waybill" lang={this.state.lang} showLanguageModal={this.showLanguageModal} />} />
                <Route path="/order/:id" exact render={(props) => <Order {...props} lang={this.state.lang} showLanguageModal={this.showLanguageModal} />} />
                <Redirect from="*" to="/" />
              </Switch>
            </BrowserRouter>
          </div>
          <div></div>
        </div>
      </div>
    )
  }
}

export default App;

