import languageJson from '../lang.json';

const getLanguage = (lang, type) => {
    if(lang === 'chi') {
        return languageJson[type].chi;
    } else if(lang === 'eng') {
        return languageJson[type].eng;
    } else if(lang === 'chi-tra') {
        return languageJson[type]['chi-tra'];
    } else {
        return languageJson[type].chi;
    }
}

export default getLanguage;