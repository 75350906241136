import React from 'react';
import './WaybillInfo.css';
import Collapse from 'react-bootstrap/Collapse';
import { MdOutlineLocationOn } from 'react-icons/md'
import { HiHeart } from 'react-icons/hi'
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import getLanguage from './Language';

class WaybillInfo extends React.Component {
    state = {
        modalShow: false,
        modalReviewShow: false,
    }

    alternateModal = () => {
        this.setState({ modalShow: !this.state.modalShow })
    }
    
    alternateReviewModal = () => {
        this.setState({ modalReviewShow: !this.state.modalReviewShow })
    }

    handleCollapseText = (event) => {
        if(event.target.outerHTML.includes("waybill-info__pod-button") && 
            !event.target.outerHTML.includes('waybill-info__pod-feedback-wrapper')) return;
        
        if(event.target.outerHTML.includes("waybill-info__feedback-button") && 
            !event.target.outerHTML.includes('waybill-info__pod-feedback-wrapper')) return;
        
        if(event.target.outerHTML.includes("path") && event.target.outerHTML.includes('d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656') && 
            !event.target.outerHTML.includes('waybill-info__pod-feedback-wrapper')) return;

            this.props.openCollapedText();
    }

    handlePODButtonClick = () => {
        this.alternateModal();
    }

    handleFeedbackButtonClick = () => {
        const googleForm = "https://docs.google.com/forms/d/e/1FAIpQLSffCy89FOkGpx2WTElaCmJgJKPmb4AmJWAaYUDMS6XlGi38RA/viewform";
        window.open(googleForm, "_blank")
    }
    
    handleReviewButtonClick = () => {
        this.alternateReviewModal();
    }
    
    getDate = (d) => {
        return d;
    }

    renderPODModal = (podArr) => {
        return <Modal centered size='lg' show={this.state.modalShow} onHide={this.alternateModal}>
            <Modal.Body>
                <Carousel>
                    {podArr && podArr.length > 0 && podArr[0] && podArr.map(pod => {
                        return <Carousel.Item>
                            <img alt="pod" src={pod} />
                        </Carousel.Item>
                    })}
                </Carousel>
            </Modal.Body>
        </Modal>
    }

    renderReviewModal = () => {
        const url = "https://www.facebook.com/ddgrouplivechannel"
        return <Modal c centered size='lg' show={this.state.modalReviewShow} onHide={this.alternateReviewModal}>
            <Modal.Body>
                {this.props.lang === "eng" && <img alt="review eng" onClick={()=> window.open(url, "_blank")} src="https://ddlive-qrcode.s3.ap-southeast-1.amazonaws.com/black-review-english.PNG" width={"100%"} />}
                {(this.props.lang === "chi" || this.props.lang === "chi-tra") && <img alt="review chi" onClick={()=> window.open(url, "_blank")} src="https://ddlive-qrcode.s3.ap-southeast-1.amazonaws.com/black-review-chinese.PNG" width={"100%"} />}
            </Modal.Body>
        </Modal>
    }

    render() {
        const podArr = this.props.pod;
        const { data } = this.props;
        return <div>
            <div className={`waybill-info__wrapper ${ this.props.open ? "" : "waybill_info__wrapper-fade" }`}>
                <div className='waybill-info'>
                    <div className='waybill-info__content' onClick={e => this.handleCollapseText(e)}>
                        <div className='waybill-info__datetime-wrapper'>
                            <div className='waybill-info__date'>{this.getDate(data[0].date)}</div>
                            <div className='waybill-info__time'>{data[0].time}</div>
                        </div>
                        <div className='waybill-info__text-wrapper'>
                            <div>
                                <div className='waybill-info__text'>{data[0].content}</div>
                                {data && data[0] && data[0].location && <div className='waybill-info__location-wrapper'>
                                    <MdOutlineLocationOn fontSize={"0.75rem"} />
                                    <div className='waybill-info__location'>{data[0].location}</div>
                                </div>}
                                <div className='waybill-info__pod-feedback-wrapper'>
                                    {podArr && podArr.length > 0 && podArr[0] && <div className='waybill-info__pod'><div className='waybill-info__pod-button' onClick={e => this.handlePODButtonClick(e)}>{getLanguage(this.props.lang, "pod")}</div></div>}
                                    {data[0].status === "delivered" && <div className='waybill-info__pod'><div className='waybill-info__feedback-button' onClick={e => this.handleFeedbackButtonClick(e)}>{getLanguage(this.props.lang, "feedback")}</div></div>}
                                    {data[0].status === "delivered" && <div className='waybill-info__pod'><div className='waybill-info__review-button' onClick={e => this.handleReviewButtonClick(e)}><HiHeart fontSize={"1rem"} /></div></div>}
                                </div>
                            </div>
                            <div className='waybill-info__circle' style={{ backgroundColor: this.props.color }}>
                                <div className='waybill-info__circle-small'></div>
                            </div>
                            <div className='waybill-info__box-white'></div>
                        </div>
                    </div>
                </div>
            </div>
            <Collapse in={this.props.open}>
                <div className='waybill-info__wrapper-opened'>
                    <div className='waybill-info'>
                        {data.map((r, i) => {
                            if(i > 0) {
                                return <div className='waybill-info__content' onClick={this.handleCollapseText}>
                                    <div className='waybill-info__datetime-wrapper'>
                                        <div className='waybill-info__date'>{this.getDate(r.date)}</div>
                                        <div className='waybill-info__time'>{r.time}</div>
                                    </div>
                                    <div className='waybill-info__text-wrapper'>
                                        <div>
                                            <div className='waybill-info__text'>{r.content}</div>
                                            {r && r.location && <div className='waybill-info__location-wrapper'>
                                                <MdOutlineLocationOn fontSize={"0.75rem"} />
                                                <div className='waybill-info__location'>{r.location}</div>
                                            </div>}
                                        </div>
                                        <div className='waybill-info__circle'>
                                            <div className='waybill-info__circle-small'></div>
                                        </div>
                                    </div>
                                </div>
                            }
                        })}
                    </div>
                </div>
            </Collapse>
            {this.renderPODModal(this.props.pod)}
            {this.renderReviewModal()}
        </div>
    }
}

export default WaybillInfo;